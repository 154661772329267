import classNames from 'classnames';
import { ChangeEvent, FocusEvent, KeyboardEvent, useState, useCallback, useEffect, useRef } from 'react';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import { UUID } from 'angular2-uuid';
import { cancelRequest } from 'app/util/util';
import { useOnOutsideClick } from 'app/hooks/use-on-outside-click.hook';
import { useTranslation } from 'react-i18next';
import './autocomplete.component.scss';

const ENTER_KEY = 'Enter';
const UP_ARROW = 'ArrowUp';
const DOWN_ARROW = 'ArrowDown';
const TAB_KEY = 'Tab';

const VALID_KEYS = [UP_ARROW, DOWN_ARROW, ENTER_KEY, TAB_KEY];
const recentSearchesMax = 5;

interface Props {
  value: AnyAutocompleteResult;
  mapper: (query: string) => Observable<AnyAutocompleteResult[]>;
  onChange: (value: AnyAutocompleteResult) => any;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => any;
  disabled?: boolean;
  suppressSingleResults?: boolean;
  name?: string;
  maxResults?: number;
  placeholder?: string;
  required?: boolean;
  selectOnClickOut?: boolean;
  labelHeader?: string;
  recentSearches?: SearchHistoryLocationJSON[];
}

export type AnyAutocompleteResult = AutocompleteResult<any>;

export interface AutocompleteResult<T> {
  id: string | number;
  title: string;
  value: T;
  isRecentSearchSelection?: boolean;
}

export const Autocomplete = (props: Props) => {
  const { value, mapper, onChange, onBlur, disabled, suppressSingleResults = true, name, maxResults, placeholder, selectOnClickOut, labelHeader, recentSearches } = props;
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [userTyped, setUserTyped] = useState(false);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<AnyAutocompleteResult[]>([]);
  const [recentMatches, setRecentMatches] = useState<SearchHistoryLocationJSON[]>(null);
  const [subscription, setSubscription] = useState<Subscription>(null);

  const maxResultsMinusRecents = 10 - recentMatches?.length;
  const resultsToDisplay = recentMatches?.length > 0 ? maxResultsMinusRecents : maxResults;

  const query = useRef(new Subject()).current;

  const ref = useRef(null);
  const optionRefs = useRef([]);

  const outsideClickHandler = useCallback(() => {
    // When user clicks out of input, run onChange like we would if they tabbed out
    if (results?.length > 0 && selectOnClickOut && userTyped) {
      selectActions(results[selectedIndex]);
    }

  }, [results, selectedIndex, userTyped, selectOnClickOut, onChange]);

  useOnOutsideClick(ref, outsideClickHandler);

  useEffect(() => {
    cancelRequest(subscription);

    const _subscription = query
      .debounceTime(500)
      .map(query => {
        setLoading(true);
        return query;
      })
      .switchMap(mapper)
      .subscribe(results => {
        setResults(results);
        setLoading(false);
      });

    setSubscription(_subscription);

    return () => cancelRequest(_subscription);

  }, []);

  useEffect(() => {
    if (recentSearches?.length > 0) {
      setRecentMatches(recentSearches.slice(0, recentSearchesMax));
    }
  }, [recentSearches]);

  const localOnBlur = (e?: FocusEvent<HTMLInputElement>) => {
    if (ref && (ref?.current as HTMLDivElement)?.contains(document.activeElement)) {
      return;
    }
    if (onBlur) {
      onBlur(e);
    }
    setIsFocused(false);
  };

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    query.next(value);
    onChange({ id: null, title: value, value });

    if (recentSearches?.length > 0) {
      filterRecentSearches(value);
    }

    setUserTyped(true);
  };

  const selectActions = (result: AnyAutocompleteResult) => {
    onChange(result);
    setIsFocused(false);
    setUserTyped(false);
    setResults([result]);
    setSelectedIndex(0);
    localOnBlur();
  };

  const onSelect = (result: AnyAutocompleteResult) => () => {
    result.isRecentSearchSelection = false;
    selectActions(result);
  };

  const onRecentSearchSelect = ({ city, stateProvinceCode, countryName, countryCode, latitude, longitude, exampleZipCode }: SearchHistoryLocationJSON) => () => {
    const locationDisplayText = getLocationDisplayText(stateProvinceCode, city, countryName);
    // if coords are missing, interimAPI is used
    const selectedResult = {
      isRecentSearchSelection: true,
      id: UUID.UUID(),
      title: locationDisplayText,
      value: {
        cityName: city,
        stateProvinceCode,
        countryCode,
        countryName,
        displayName: locationDisplayText,
        coordinate: {
          latitude,
          longitude,
        },
        zipCodes: exampleZipCode ? [exampleZipCode] : null,
      }
    };

    selectActions(selectedResult);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (VALID_KEYS.indexOf(e.key) === -1 || !showDropdown()) {
      return;
    }

    if (e.key !== TAB_KEY) {
      e.preventDefault();
    }

    let newSelectedIndex = selectedIndex;

    switch (e.key) {
      case UP_ARROW:
        newSelectedIndex--;
        if (newSelectedIndex < 0) {
          newSelectedIndex = 0;
        }
        break;
      case DOWN_ARROW:
        newSelectedIndex++;
        if (newSelectedIndex >= results?.length) {
          newSelectedIndex = results?.length - 1;
        }
        break;
      case ENTER_KEY:
      case TAB_KEY:
        if (!results?.length) {
          return;
        }
        onSelect(results[newSelectedIndex])();
        newSelectedIndex = 0;
    }
    // this allows using the arrow keys to scroll the selected item into view if the autocomplete has a scrollbar.
    if (optionRefs[newSelectedIndex]) {
      optionRefs[newSelectedIndex].scrollIntoView(false);
    }
    setSelectedIndex(newSelectedIndex);
  };

  const getLocationDisplayText = (stateProvinceCode: string, city: string, countryName: string) => {
    return stateProvinceCode ? `${city}, ${stateProvinceCode}, ${countryName}` : `${city}, ${countryName}`;
  }

  const showDropdown = () => {
    const hasResults = results && results.length && // has search results
      !(suppressSingleResults && (results.length === 1 && value === results[0])) && // unless there is only 1 item, and it is already selected
      isFocused;

    const hasRecentMatches = recentMatches && recentMatches.length > 0 && isFocused;

    return Boolean(hasResults || hasRecentMatches);
  }

  const getActiveClasses = (result: AutocompleteResult<any>, index: number) => {
    return classNames({ 'selected': index === selectedIndex, 'current': value && value.id === result.id });
  }

  const filterRecentSearches = (value: string) => {
    const parts = value.split(',').map(v => v.toLowerCase().trim());
    const recentMatches = recentSearches.filter(record => {
      // city requires a comma after it
      const city = `${record.city.toLocaleLowerCase()},`;
      if (parts[0]?.length > 0 && !city.startsWith(parts[0])) {
        return false;
      }

      if (parts[1]?.length > 0 && parts[1].length <= 2) {
        const startsWithStateCode = record.stateProvinceCode != null && record.stateProvinceCode.toLocaleLowerCase().startsWith(parts[1]);
        const startsWithCountryCode = record.countryCode != null && record.countryCode.toLocaleLowerCase().startsWith(parts[1]);
        const startsWithCountryName = record.countryName != null && record.countryName.toLocaleLowerCase().startsWith(parts[1]);

        if (!startsWithStateCode && !startsWithCountryCode && !startsWithCountryName) {
          return false;
        }
      }

      if (parts[1]?.length > 2) {
        const includesStateCode = record.stateProvinceCode != null && parts[1].includes(record.stateProvinceCode.toLocaleLowerCase());
        const includesCountryCode = record.countryCode != null && parts[1].includes(record.countryCode.toLocaleLowerCase());
        const includesCountryName = record.countryName != null && parts[1].includes(record.countryName.toLocaleLowerCase());
        const startsWithCountryName = record.countryName != null && record.countryName.toLocaleLowerCase().startsWith(parts[1]);

        if (!startsWithCountryName && !includesStateCode && !includesCountryCode && !includesCountryName) {
          return false;
        }
      }

      return true;
    });

    const maxDisplay = recentMatches.slice(0, recentSearchesMax);

    setRecentMatches(maxDisplay);
  }

  return (
    <div className="autocomplete" ref={ref}>
      <span className={loading ? ' ns-icon ns-loading' : ''} />
      <input
        type="text"
        id={name}
        name={name}
        className="input form-control"
        onBlur={localOnBlur}
        onFocus={() => setIsFocused(true)}
        onChange={onInput}
        onKeyDown={onKeyDown}
        value={value.title || ''}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete="off"
      />
      {showDropdown() &&
        <div className="suggestions">
          {recentMatches?.length > 0 &&
            <>
              <span className="small text-muted space-outer-left-sm">
                {t('RECENT')}
              </span>
              <ul className="recent-searches">
                {recentMatches.map((result: any, index) =>
                  <li key={index} className="recent-search-item">
                    <a
                      href="#"
                      className="recent-search-content"
                      onMouseDown={onRecentSearchSelect(result)}
                      onTouchStart={onRecentSearchSelect(result)}
                    >
                      <span className="history-icon" />
                      <span>{getLocationDisplayText(result.stateProvinceCode, result.city, result.countryName)}</span>
                    </a>
                  </li>
                )}
              </ul>
            </>
          }
          {results?.length > 0 &&
            <ul className="autocomplete-results">
              <label>{labelHeader}</label>
              {results?.slice(0, resultsToDisplay).map((result: AnyAutocompleteResult, index) =>
                <li ref={ref => optionRefs[index] = ref} key={result.id}>
                  <a className={getActiveClasses(result, index)}
                    onMouseDown={onSelect(result)}
                    onTouchStart={onSelect(result)}
                  >{result.title}</a>
                </li>
              )}
            </ul>
          }
        </div>
      }
    </div>
  );
}