import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs/Observable';
import { useCallback, useContext, useMemo } from 'react';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/mergeMap';
import { useTranslation } from 'react-i18next';
import { Autocomplete, RawAutocompleteResult } from 'shared/components/autocomplete/autocomplete.component';
import { Autocomplete as AutocompleteV2 } from 'shared/components/autocomplete/autocomplete-two.component';
import { LocationSelectContext } from 'shared/components/location-select/location-select-context.component';
import { useResources } from 'app/hooks/store/use-resources.hook';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { useSearchAnalytics } from 'features/analytics/hooks/use-search-analytics.hook';

const getLocationName = (locality: any) => locality.displayName || '';
const createLocation = (name: string) => ({ id: UUID.UUID(), displayName: name });
const getLocalityID = () => UUID.UUID();

export interface OwnProps {
  required?: boolean;
  noLabels?: boolean;
  maxResults?: number;
  useV2?: boolean;
}

export const LocalAutocomplete = ({ noLabels, maxResults, required, useV2 }: OwnProps) => {
  const { label, id, disabled, location, onPlaceChange, fetchLocations, onBlur } = useContext(LocationSelectContext);
  const { t } = useTranslation();
  const { place: value } = location;
  const uniqueId = useMemo(() => UUID.UUID(), []);
  const { dispatchRecentSearchSelectionEvent } = useSearchAnalytics();

  const onChange = useCallback(({ value, isRecentSearchSelection }: RawAutocompleteResult) => {
    const locality = typeof value === 'string' ? createLocation(value) : value;
    onPlaceChange(locality);

    const isFieldSelected = (typeof isRecentSearchSelection === 'boolean');

    if (isFieldSelected) {
      dispatchRecentSearchSelectionEvent({ isRecentSearchSelection, locationField: label });
    }

    const isFieldCleared = (typeof isRecentSearchSelection === 'undefined' && !value);

    if (isFieldCleared) {
      dispatchRecentSearchSelectionEvent({ isRecentSearchSelection: false, locationField: label });
    }
  }, [onPlaceChange]);

  const locationMapper = useCallback((query) => {
    return fetchLocations(query).mergeMap(data =>
      Observable.of(data[0]?.results?.map(locality => ({ id: getLocalityID(), title: locality.displayName, value: locality })))
    );
  }, [fetchLocations]);

  const selectedLocation = useMemo(() =>
    value
      ? { id: uniqueId, title: getLocationName(value), value }
      : { id: 0, title: '', value },
    [value]);
  const resources = useResources();

  const searchHistoryLocations = useSelector(state => state.findLoads?.searchHistory?.history);

  const recentLocations = useMemo(() => {
    if (resources[label] === resources.ORIGIN && searchHistoryLocations?.origins) {
      return searchHistoryLocations.origins;
    }
    if (resources[label] === resources.DESTINATION && searchHistoryLocations?.destinations) {
      return searchHistoryLocations.destinations;
    }
    return [];
  }, [searchHistoryLocations]);

  const labelClassName = !noLabels ? (required ? 'required' : '') : 'visually-hidden';

  return (
    <div>
      <label htmlFor={`${id || label || uniqueId}-place-field`} className={labelClassName}>
        {t(label)}
      </label>
      {!useV2 ?
        <Autocomplete
          required={required}
          placeholder={resources.CITY_OR_POSTAL_CODE_PLACEHOLDER}
          name={`${id || label || uniqueId}-place-field`}
          maxResults={maxResults}
          value={selectedLocation}
          mapper={locationMapper}
          onChange={onChange}
          onBlur={onBlur}
          disabled={Boolean(disabled)}
          selectOnClickOut={true}
          recentSearches={recentLocations}
        />
        : <AutocompleteV2
          required={required}
          placeholder={resources.CITY_OR_POSTAL_CODE_PLACEHOLDER}
          name={`${id || label || uniqueId}-place-field`}
          maxResults={maxResults}
          value={selectedLocation}
          mapper={locationMapper}
          onChange={onChange}
          onBlur={onBlur}
          disabled={Boolean(disabled)}
          selectOnClickOut={true}
          recentSearches={recentLocations}
        />
      }
    </div>
  );
};
