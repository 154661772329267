import React from "react";
import { useDispatch } from "react-redux";
import * as a from "../redux/analytics-v2.actions";

export const useSearchAnalytics = () => {
    const dispatch = useDispatch();

    const dispatchSubmitSearchEvent = React.useCallback((searchCriteria: AvailableLoadSearchCriteriaJSON, searchCorrelationId?: string) => { dispatch(a.setSubmitSearchEvent({ searchCriteria, searchCorrelationId })) }, []);
    const dispatchSearchResultsEvent = React.useCallback((searchResultsEvent: a.SearchResultsEvent) => { dispatch(a.setSearchResultsEvent(searchResultsEvent)) }, []);
    const dispatchEmptySearchResultsEvent = React.useCallback(() => { dispatch(a.setEmptySearchResultsEvent()) }, []);
    const dispatchRecommendedLoadsListViewedEvent = React.useCallback((searchResultsEvent: a.SearchResultsEvent) => { dispatch(a.setRecommendedLoadListViewedEvent(searchResultsEvent)) }, []);
    const dispatchRecentSearchSelectionEvent = React.useCallback((recentSearchSelectionEvent: a.RecentSearchSelectionEvent) => { dispatch(a.setRecentSearchSelectionEvent(recentSearchSelectionEvent)) }, []);
    return {
        dispatchSubmitSearchEvent,
        dispatchEmptySearchResultsEvent,
        dispatchSearchResultsEvent,
        dispatchRecommendedLoadsListViewedEvent,
        dispatchRecentSearchSelectionEvent,
    };
};
